import axios from "axios";

export const handleFetchNews = async (payload, onSuccess, onError) => {
    console.log(payload)
    const response = await axios.get(
        '/manager/expert3/xp3/get-news/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading news failed. Please try again.')
    }
}

export const handleFetchNewsContent = async (payload, onSuccess, onError) => {
    const {newsId} = payload
    const response = await axios.get(
        '/manager/expert3/xp3/get-news-content/' + newsId + '/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading news content failed. Please try again.')
    }
}