<template>
    <div class="flex flex-1 w-full h-full">
        <div v-if="!selectedNews" class="flex flex-1 w-full h-full items-center justify-center">
            <div class="text-center">
                <i class="fal fa-envelope text-gray-600 text-5xl"/>
                <p class="mt-1 text-sm text-gray-500">Click on the news to see the content.</p>
            </div>
        </div>
        <div v-else class="flex flex-1 w-full h-full">
            <div v-if="loading" class="flex w-full items-center my-6">
                <autralis-loading-comp/>
            </div>

            <div v-else-if="!loading && loadingError" class="flex flex-1 w-full h-full items-center justify-center ">
                <div class="text-center">
                    <i class="fal fa-exclamation-circle text-red-600 text-5xl"/>
                    <p class="mt-1 text-sm text-red-500">{{ loadingError }}</p>
                </div>
            </div>
            <div v-else class="flex flex-col w-full">
                <div class="lg:flex lg:items-center lg:justify-between p-6 bg-gray-50 w-full border-t border-gray-100 sticky top-0">
                    <div class="flex-1 min-w-0">
                        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                            {{ news.title }}
                        </h2>
                        <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                            <div class="mt-2 flex items-center text-sm text-gray-500">
                                <i class="fas fa-calendar mr-1 text-xs"/> Published: <span class="font-semibold ml-2">{{ formatDate(news.published) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="overflow-x-scroll flex w-full p-6" v-html="news.content"/>
            </div>
        </div>

    </div>
</template>

<script>
import Loading from "@/components/loading";
import moment from "moment";
import {handleFetchNewsContent} from "@/master/views/Home/news/handlers";

export default {
    name: "NewsContent",
    data() {
        return {
            loading: false,
            loadingError: null,
            news: null,
            selectedNews: null
        }
    },

    watch: {
        '$store.state.master.selectedNews': function (newVal) {

            this.selectedNews = newVal;
            this.fetchNews(newVal)
        }
    },

    methods: {
        initializeNews() {
            if (this.$store.getters['master/selectedNews']) {
                this.selectedNews = this.$store.getters['master/selectedNews'];
                this.fetchNews(this.$store.getters['master/selectedNews'])
            }
        },

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY, HH:mm') + ' (' + moment(date, "YYYYMMDD").fromNow() + ')';
        },

        async fetchNews(newsId) {
            try {
                this.loading = true;
                this.loadingError = null

                const payload = {
                    newsId: newsId
                };

                await handleFetchNewsContent(payload, (response) => {
                    this.news = {
                        id: response.id,
                        title: response.title,
                        published: response.published,
                        content: response.content,
                    };
                }, (error) => {
                    this.loadingError = error
                })

                this.loading = false;

            } catch (err) {
                this.loadingError = 'The news could not be fetched. Please try again.'
                this.loading = false;
            }
        }
    },

    created() {
        this.initializeNews();
    },

    components: {
        'autralis-loading-comp': Loading
    }
}
</script>

<style scoped>

</style>